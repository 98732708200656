import 'sweetalert2/dist/sweetalert2.css';
import VueSweetalert2 from 'vue-sweetalert2';
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Dayjs from "vue-dayjs";
import VueExcelXlsx from "vue-excel-xlsx"

import vuetify from "./plugins/vuetify";

import VueMask from 'v-mask'
import Cloudinary from 'cloudinary-vue';
import AuthService from './services/AuthService';
import axios from 'axios';

if (localStorage.token) {
  AuthService.setAuthHeader()
  store.state.userlogeado.userlogin = true
  axios.get(`${process.env.VUE_APP_BASE_URL}/api/user`).then((response)=>{
    store.commit("setDataLoginUser", response.data);
  })
}else{
  AuthService.logout
}


const options = {

};



Vue.use(VueSweetalert2);


Vue.use(VueMask);

Vue.config.productionTip = false;


Vue.use(Dayjs);
Vue.use(VueExcelXlsx);
Vue.use(Cloudinary, {
  configuration: {
    cloudName: "devand3d"
  }
});



// store.dispatch("GETUSERLOGIN");



// store.dispatch("GETALLITEMSASSIGNABLE")
// store.dispatch("GETBOMBEROS")
// store.dispatch('GETALLTIPOS')
// store.dispatch('GETALLMARCAS')
// store.dispatch('GETALLMODELOS')
// store.dispatch('GETALLESTADOS')



  new Vue({
    router,
    store,
    vuetify,
   
    render: (h) => h(App),
  }).$mount("#app");



