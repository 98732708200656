import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store"
import axios from "axios";
Vue.use(VueRouter);

const routes = [
  {
    path:"/",
    redirect:"/login"
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login.vue"),
     
  },
  {
    path: "/principal",
    name: "Principal",
    component: () =>
      import(/* webpackChunkName: "principal" */ "../views/principal.vue"),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: "/menu",
    name: "Menu",
    component: () => import(/* webpackChunkName: "menu" */ "../views/Menu.vue"),
    meta:{
      requiresAuth: true
    }
  },
  // {
  //   path: "/bodega/:id/:bodega",
  //   name: "Bodega",
  //   component: () =>
  //     import(/* webpackChunkName: "bodega" */ "../views/bodega.vue"),
  //     meta:{
  //       requiresAuth: true
  //     }
  // },
  // {
  //   path: "/bodegaepp",
  //   name: "BodegaEpp",
  //   component: () =>
  //     import("../views/bodegaEpp.vue"),
  //     meta:{
  //       requiresAuth: true
  //     }
  // },
  // {
  //   path: "/eppstrabaja",
  //   name: "EPPTrabajadores",
  //   component: () =>
  //     import("../views/epptrabaja.vue"),
  //     meta:{
  //       requiresAuth: true
  //     }
  // },
  // {
  //   path: "/inspecciones",
  //   name: "Inspecciones",
  //   component: () =>
  //     import(/* webpackChunkName: "herramientas" */ "../views/inspecciones.vue"),
  //     meta:{
  //       requiresAuth: true
  //     }
  // },
  // {
  //   path: "/dialoginspecciones/:id",
  //   name: "Dialog_Inspecciones",
  //   component: () =>
  //     import(/* webpackChunkName: "herramientas" */ "../components/dialogImpresionInspTerreno.vue"),
  //     props: true,
  //     meta:{
  //       requiresAuth: true
  //     }
  // },
  {
    path: "/bodegaprincipal",
    name: "BodegaPrincipal",
    component: () =>
      import(
        /* webpackChunkName: "bodegaPrincipal" */ "../views/BodegaPrincipal.vue"),
        meta:{
          requiresAuth: true
        },
      },
      {
        path: "/inventarioglobal",
        name: "InventarioGlobal",
        component: () =>
          import(
            /* webpackChunkName: "InventarioGlobal" */ "../views/inventarioGlobal.vue"),
            
            meta:{
              requiresAuth: true
            },
          },
  {
    path: "/administracion",
    name: "Administracion",
    component: () =>
      import(
        /* webpackChunkName: "adminHerramientas" */ "../views/adminHerramientas.vue"),
        meta:{
          requiresAuth: true
        },
    children: [
      {
        path: "herramientas",
        name: "Herramientas",
        component: () =>
          import(/* webpackChunkName: "herramientas" */ "../components/herramientastabla.vue"
          ),
      },
      {
        path: "marcas",
        name: "Marcas",
        component: () =>
          import(/* webpackChunkName: "marcas" */ "../components/marcasTable.vue"
          ),
      },
      {
        path: "modelos",
        name: "Modelos",
        component: () =>
          import(/* webpackChunkName: "modelos" */ "../components/modelosTable.vue"
          ),
      },
      // {
      //   path: "epps",
      //   name: "EPP",
      //   component: () =>
      //     import(/* webpackChunkName: "herramientas" */ "../components/eppcomponent.vue"
      //     ),
      // },
      // {
      //   path: "bodegas",
      //   name: "Bodegas",
      //   component: () =>
      //     import(/* webpackChunkName: "herramientas" */ "../components/bodegasAdmin.vue"
      //     ),
      // },      
      
      // {
      //   path: "usuarios",
      //   name: "Usuarios",
      //   component: () =>
      //     import(/* webpackChunkName: "herramientas" */ "../components/usuariosAdmin.vue"
      //     ),
      // },
      {
        path: "voluntarios",
        name: "Voluntarios",
        component: () =>
          import(/* webpackChunkName: "herramientas" */ "../components/voluntariosAdmin.vue"
          ),
      },
    ],
  },
];



const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// firebase.getCurrentUser = () => {
//   return new Promise((resolve, reject) => {
//     const unsubscribe = firebase.auth().onAuthStateChanged(user => {
//       unsubscribe();
//       resolve(user);
//       if(user != null){
//         store.state.userlogeado.userlogin = true
//       }
//     }, reject);
//   }
// )};

router.beforeEach((to,from, next)=>{
  const user = store.state.userlogeado.userlogin

  console.log("xx", store.state.userlogeado.userlogin);
  const rutaprotegida = to.matched.some(record => record.meta.requiresAuth)
  if( !user && rutaprotegida){
    console.log("1");
       next("/login")
      }
      else if(user && !rutaprotegida && to.name == "Login"){
        console.log('2');
        next("/principal")
      }
      else if(user && !rutaprotegida){
        console.log('3');
        next("/login")
      }
      else if(user == null && rutaprotegida){
        console.log("hola");
        next()
      }
      else{
        next()
      }
})

export default router;
