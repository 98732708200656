import axios from 'axios';
import state from '../store/index.js'
const AuthService = {
  async login(rut, password) {
    try {
      const response = await axios.post(`${process.env.VUE_APP_BASE_URL}/api/login`, { rut, password });
      console.log(response.data);
      localStorage.setItem('token',response.data.access_token)
      return response;
    } catch (error) {
      throw error;
    }
  },
  logout() {
    localStorage.removeItem('token');
    delete axios.defaults.headers.Authorization
  },
  setAuthHeader() {

      const token = localStorage.getItem('token');
      if (token) {
        console.log("token", token);
        axios.defaults.headers = {Authorization : `Bearer ${token}`}
      }
     

  }
};

export default AuthService;
